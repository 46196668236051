import React from "react";
import SurveyApp from "./SurveyApp";
import {ThemeProvider, CSSReset, Flex} from "@chakra-ui/core";
import { theme } from "@chakra-ui/core";
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';

Amplify.configure(awsconfig);


const customTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    sterilisBlue: "#22b0bf",
    sterilisOrange: "darkorange",
    invalidRed: "#f54e4e",
  },
};

export default function App() {
  return (
    <Flex
    justify='center'
    height='100vh'
  >
      <ThemeProvider theme={customTheme}>
        <CSSReset/>
        <SurveyApp/>
      </ThemeProvider>
    </Flex>
  );
}

import {Box, Text} from "@chakra-ui/core";
import React from "react";

export const SterTextHeader = props => {
  return <Box
      bg={props['backgroundColor']}
      fontSize="2xl"
      color={props['color']}
      padding="7px"
      width="100%"
      textAlign="center"
      flexGrow={props['flexGrow']}
    >
      <Text>{props.children}</Text>
    </Box>
};

SterTextHeader.defaultProps = {
  backgroundColor: "sterilisOrange",
  color: "white",
  flexGrow: 0,
};
import Header from "./Header";
import Survey from "./Survey";
import {Flex} from "@chakra-ui/core";
import React from "react";

export default function SurveyApp(){
  return <Flex
    align='center'
    direction='column'
    width={['450px', '450px', '550px', '750px', ]}
  >
      <Header />
      <Survey/>
    </Flex>
}
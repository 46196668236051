import {useForm} from "react-hook-form";
import React, {useState} from "react";
import {
  Button,
  Box,
  Flex
} from "@chakra-ui/core";
import {SterTextHeader} from './SterTextHeader';
import {
  FormFieldRadio,
  FormFieldInput,
  FormFieldSelect,
  AddressField,
  FormFieldRadioYesNo
} from './FormFields';
import {API} from 'aws-amplify';
import * as Sentry from "@sentry/browser";

const API_NAME = 'sitesurvey';


export default function Survey() {
  const {handleSubmit, register, errors} = useForm();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [surveySent, setSurveySent] = useState(false);

  const [errorOccurred, setErrorOccurred] = useState(false);


  const [address, setAddress] = useState('');


  const [resubmitSurvey, setResubmitSurvey] = useState({});

  function onSubmit(values) {
    const siteSurvey = {};
    //Mutate all boolean values from checkboxes to pretty Yes or No
    Object.keys(values).forEach(key => {
      if (typeof values[key] === 'boolean') {
        siteSurvey[key] = values[key] ? 'Yes' : 'No'
      } else {
        siteSurvey[key] = values[key];
        if(key === 'Contact information'){
          //After contact information, insert the Customer address. This will make it appear after Contact Information
          //in the sent email

          // add the address provided by Geosuggest via the setAddress hook to the siteSurvey obj
          siteSurvey['Customer address'] = address;
        }
      }
    });

    setIsSubmitting(true);
    API.post(API_NAME, '/site-survey', {
      body: siteSurvey,
    })
      .then(() => {
        setIsSubmitting(false);
        setSurveySent(true)
      })
      .catch(err => {
        Sentry.captureException(err);
        setIsSubmitting(false);
        setSurveySent(true);
        setErrorOccurred(true);
        setResubmitSurvey(siteSurvey)
      });
  }

  function resubmit() {
    setIsSubmitting(true);
    API.post(API_NAME, '/site-survey', {
      body: resubmitSurvey,
    })
      .then(() => {
        setIsSubmitting(false);
        setSurveySent(true)
      })
      .catch(err => {
        Sentry.captureException(err);
        setIsSubmitting(false);
        setSurveySent(true);
        setErrorOccurred(true);
        setResubmitSurvey(resubmitSurvey)
      });
  }

  return <Box width="100%" height='100%'>
    {surveySent ?
      <Flex
        direction='column'
        height='100%'
      >
        {errorOccurred ? <>
            <SterTextHeader backgroundColor='invalidRed' color='white' flexGrow='1'>Survey failed to send email.</SterTextHeader>
            <Button
              marginTop={4}
              color="sterilisBlue"
              bg="white"
              border="2px"
              borderColor="grey"
              float="right"
              width="100%"
              height="25vh"
              fontSize="2xl"
              onClick={() => resubmit()}
              isLoading={isSubmitting}
              type="submit">
              Try again?
            </Button>
          </> :
          <SterTextHeader backgroundColor='green' color='black' flexGrow='1'>Survey successfully emailed!</SterTextHeader>
        }
        <Button
          marginTop={4}
          color="sterilisBlue"
          bg="white"
          border="2px"
          borderColor="grey"
          float="right"
          width="100%"
          marginBottom='10px'
          height="25vh"
          fontSize="2xl"
          onClick={() => setSurveySent(false)}
          type="submit">
          Reset form
        </Button>
      </Flex>
      :
      <form onSubmit={handleSubmit(onSubmit)}>
        <SterTextHeader>Customer Information</SterTextHeader>

        <FormFieldInput
          register={register}
          labelText={'Customer name'}
          inputPlaceholder='e.g. Sterilis Solutions'
        />

        <AddressField
          setAddress={setAddress}
          labelText={'Customer address'}
          inputPlaceholder='Begin typing an address and suggestions will appear'
          isRequired={false}
        />

        <FormFieldInput
          register={register}
          labelText={'Contact information'}
          inputPlaceholder='e.g. phone number, email address'
          isRequired={false}
        />


        <SterTextHeader>Waste Detail Information</SterTextHeader>
        <FormFieldRadioYesNo
          register={register}
          labelText={'Does customer have an existing Waste Management plan?'}
          errors={errors}
        />

        <FormFieldSelect
          register={register}
          labelText={'Waste stream type'}
          options={[
            {
              'value': 'Sharps',
            },
            {
              'value': 'Red Bags',
            },
            {
              'value': 'Both Red Bags and Sharps',
            },
            {
              'value': 'Dialyzers',
            },
            {
              'value': 'Airport waste',
            },
            {
              'value': 'Cannabis waste',
            },

          ]}
        />

        <FormFieldRadioYesNo
          register={register}
          labelText={'Does the customer segregate their waste?'}
          errors={errors}
        />

        <SterTextHeader>Building Information</SterTextHeader>

        <FormFieldRadioYesNo
          register={register}
          labelText={'Ramp at Install Location?'}
          errors={errors}
        />

        <FormFieldRadioYesNo
          register={register}
          labelText={'Stairs at Install Location?'}
          errors={errors}
        />

        <FormFieldRadioYesNo
          register={register}
          labelText={'Loading dock at Install Location?'}
          errors={errors}
        />

        <FormFieldRadioYesNo
          register={register}
          labelText={'Does Elevator/Listed Capacity Exceed 1100 lbs?'}
          errors={errors}
        />

        <FormFieldInput
          register={register}
          labelText={'Width of Door Entry'}
          inputPlaceholder='e.g. 10 ft'
        />

        <FormFieldRadioYesNo
          register={register}
          labelText={'Room Ventilation / Return / Exhaust?'}
          errors={errors}
        />

        <FormFieldRadioYesNo
          register={register}
          labelText={'Is the room kept between 68°F and 76°F?'}
          errors={errors}
        />

        <FormFieldRadioYesNo
          register={register}
          labelText={'Does the room have air conditioning?'}
          errors={errors}
        />

        <FormFieldInput
          register={register}
          labelText={'Space/room dimensions'}
          inputPlaceholder="e.g. 10 x 15 ft"
        />

        <FormFieldInput
          register={register}
          labelText={'Measured distance to Electrical Outlet'}
          inputPlaceholder="e.g. 10 ft"
        />

        <FormFieldRadioYesNo
          register={register}
          labelText={'220V 30A-10 l4-30 Electrical Outlet installed?'}
          errors={errors}
        />

        <SterTextHeader>Network Information</SterTextHeader>

        <FormFieldRadioYesNo
          register={register}
          labelText={'WiFi Access?'}
          errors={errors}
        />

        <FormFieldRadio
          register={register}
          labelText={'Network connection type'}
          defaultValue={'Wired'}
          radioButtons={[
            {
              'value': 'Wireless',
            },
            {
              'value': 'Wired',
            },
          ]}
        />

        <FormFieldRadio
          register={register}
          // TODO: provide a lil helper question mark thing to put in the following text
          // (Customer IT to provide information to Sterilis Solutions prior to installation if Static IP address is required)
          labelText={'Dynamic or Static IP address'}
          defaultValue={'Dynamic'}
          radioButtons={[
            {
              'value': 'Static'
            },
            {
              'value': 'Dynamic',
            },
          ]}
        />

        <FormFieldInput
          register={register}
          labelText='Wifi Strength'
          inputPlaceholder='e.g. -50dBm'
          isRequired={false}
        />

        <Button
          marginTop={4}
          color="sterilisBlue"
          bg="white"
          border="2px"
          borderColor="grey"
          float="right"
          width="100%"
          height="75px"
          marginBottom="10px"
          fontSize="2xl"
          isLoading={isSubmitting}
          type="submit">
          Submit
        </Button>
      </form>
    }
  </Box>

}
import {Flex, FormControl, FormLabel, Radio, RadioGroup, Input, Checkbox, Select} from "@chakra-ui/core";
import {LabelText} from "./LabelText";
import React from "react";
import Geosuggest from "react-geosuggest";
import './css/GeoSuggest.css'

const FormBox = props => <Flex
  direction={['column', 'column', 'column', 'row']}
  border='1px solid grey'
  height={['175px', '175px', '175px', '125px']}
>{props.children}</Flex>;

export const FormFieldRadio = props => {
  return <FormControl as="fieldset">
    <FormBox>
      <FormLabel
        pr="0" //overwrite default FormLabel padding right
        pb="0" //overwrite default FormLabel padding bottom
        as="legend">
        <LabelText>{props['labelText']}</LabelText>
      </FormLabel>
      <RadioGroup
        d='flex'
        justifyContent='space-evenly'
        alignItems='center'
        width='100%'
        height='100%'
        defaultValue={props['defaultValue']}
        name={props['labelText']}
        isInline
      >
        {
          props.radioButtons.map((radioBtn, idx) => {
            return <Radio
              // using just the map's idx for a key is a bad idea
              // here we're using a unique combination of the radio btn's value + the inputs name + the map idx
              key={`${idx}_${props['labelText']}_${radioBtn['value']}`}
              ref={props.register}
              size="lg"
              value={radioBtn['value']}
            >
              {radioBtn['value']}
            </Radio>
          })
        }
      </RadioGroup>
    </FormBox>
  </FormControl>
};


FormFieldRadio.defaultProps = {
  radioButtons: [],
  labelText: '',
  defaultValue: '',
};


export const FormFieldRadioYesNo = props => {
  return <FormControl as="fieldset">
    <FormBox>
      <FormLabel
        pr="0" //overwrite default FormLabel padding right
        pb="0" //overwrite default FormLabel padding bottom
        as="legend">
        <LabelText>{props['labelText']}</LabelText>
      </FormLabel>
      <RadioGroup
        d='flex'
        justifyContent='space-evenly'
        alignItems='center'
        width='100%'
        height='100%'
        isRequired
        borderWidth={props.errors[props['labelText']] && '3px'}
        borderColor={props.errors[props['labelText']] && 'invalidRed'}
        name={props['labelText']}
        isInline
      >
        <Radio
          ref={props.register({ required: true })}
          size="lg"
          isRequired
          value={'Yes'}
        >
          Yes
        </Radio>
        <Radio
          ref={props.register({ required: true })}
          size="lg"
          isRequired
          value={'No'}
        >
          No
        </Radio>
      </RadioGroup>
    </FormBox>
  </FormControl>
};


FormFieldRadioYesNo.defaultProps = {
  radioButtons: [],
  labelText: '',
  defaultValue: 'No',
  errors : {},
};

export const FormFieldInput = props => {
  return <FormControl>
    <FormBox
    >
      <FormLabel
        pr="0" //overwrite default FormLabel padding right
        pb="0" //overwrite default FormLabel padding bottom
        htmlFor={props['labelText']}
      >
        <LabelText>{props['labelText']}</LabelText>
      </FormLabel>
      <Input
        id={props['labelText']}
        name={props['labelText']}
        placeholder={props['inputPlaceholder']}
        height='100%'
        width={['100%', '100%', '100%', '80%']}
        isRequired={props['isRequired']}
        border='0'
        ref={props.register}
      />
    </FormBox>
  </FormControl>
};


FormFieldInput.defaultProps = {
  inputPlaceholder: 'Enter value here...',
  isRequired: true,
};

export const AddressField = props => {
  return <FormControl>
    <FormBox
    >
      <FormLabel
        pr="0" //overwrite default FormLabel padding right
        pb="0" //overwrite default FormLabel padding bottom
        htmlFor={props['labelText']}
      >
        <LabelText>{props['labelText']}</LabelText>
      </FormLabel>

      <Geosuggest
        id={props['labelText']}
        name={props['labelText']}
        ref={props.register}
        autocomplete="off"
        onSuggestSelect={(suggestion) => suggestion && props.setAddress(suggestion['description'])}
        placeholder={props['inputPlaceholder']}
      />
    </FormBox>
  </FormControl>
};


FormFieldInput.defaultProps = {
  inputPlaceholder: 'Enter value here...',
  isRequired: true,
};


export const FormFieldCheckbox = props => {
  return <FormControl>
    <FormBox
    >
      <FormLabel
        pr="0" //overwrite default FormLabel padding right
        pb="0" //overwrite default FormLabel padding bottom
        htmlFor={props['labelText']}
      >
        <LabelText>{props['labelText']}</LabelText>
      </FormLabel>
      <Flex
        align='center'
        justify='space-around'
        width='100%'
        height='100%'
      >
        <Checkbox
          id={props['labelText']}
          name={props['labelText']}
          ref={props.register}
          size='lg'
        />
      </Flex>
    </FormBox>
  </FormControl>
};


FormFieldCheckbox.defaultProps = {

};


export const FormFieldSelect = props => {
  return <FormControl>
    <FormBox>
      <FormLabel
        pr="0" //overwrite default FormLabel padding right
        pb="0" //overwrite default FormLabel padding bottom
        htmlFor={props['labelText']}
      >
        <LabelText>{props['labelText']}</LabelText>
      </FormLabel>

      <Select
        name={props['labelText']}
        // This is how you'd use Custom validation with React Hook Form
        // ref={props.register({ required: {value: true, message : 'This field is required.'}})}
        ref={props.register}
        isRequired={props['isRequired']}
        placeholder={props['inputPlaceholder']}
        height={['100%', '100%', '100%', '100%']}
        minHeight='110px'
      >
        {
          props.options.map((option, idx) => {
            return <option
              key={`${idx}_${props['labelText']}_${option['value']}`}
              value={option['value']}
            >
              {option['value']}
            </option>
          })
        }
      </Select>
    </FormBox>
  </FormControl>
};


FormFieldSelect.defaultProps = {
  labelText: '',
  inputPlaceholder: 'Select option...',
  isRequired: true,
  errors: {},
};





import React from "react";
import {Box, Text} from "@chakra-ui/core";

export const LabelText = props => {
  return <Box
    bg="sterilisBlue"
    fontSize="xl"
    color="white"
    width={['100%', '100%', '100%', '235px']}
    height='100%'
    d='flex'
    alignItems='center'
    justifyContent='center'
    padding='15px'
  >
    <Text textAlign="center">{props.children}</Text>
  </Box>
};